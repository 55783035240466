<template>
  <div v-if="coupon !== null">
    <b-row>
      <b-col cols="12">
        <b-card>

          <b-form>
            <b-row>

              <!-- Field:  name -->
              <b-col
                  cols="12"
                  md="6"
              >
                <b-form-group
                    label="کد کوپن"
                    label-for="code"
                >
                  <b-form-input
                      id="code"
                      v-model="coupon.code"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: isOnline -->
              <b-col
                  cols="12"
                  md="6"
              >
                <b-form-group
                    label="نوع کوپن"
                    label-for="type"
                >
                  <v-select
                      v-model="coupon.type"
                      :options="typeOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="type"
                  />
                </b-form-group>
              </b-col>

              <!-- Field:  sessionsCount -->
              <b-col
                  v-if="coupon.type !== 0"
                  cols="12"
                  md="6"
              >
                <b-form-group
                    label="ارزش"
                    label-for="couponValue"
                >
                  <b-form-input
                      id="couponValue"
                      v-model="coupon.couponValue"
                  />
                </b-form-group>
              </b-col>

              <!-- Field:  sessionsCount -->
              <b-col
                  cols="12"
                  md="6"
              >
                <b-form-group
                    label="شناسه محصول"
                    label-for="productId"
                >
                  <b-form-input
                      id="productId"
                      v-model="coupon.productId"
                  />
                </b-form-group>
              </b-col>

              <!-- Field:  startTime -->
              <b-col
                  cols="12"
                  md="6"
              >
                <b-form-group
                    label="تاریخ و زمان شروع"
                    label-for=""
                >
                  <input
                      type="text"
                      :id="`start${index}`"
                      placeholder="شروع"
                      v-model.trim="coupon.startDate"
                      class="form-control form-control-lg w-11/12 2xl:h-12 h-10 mx-auto mt-1 mb-1 text-sm block w-full rounded-full text-center bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder-inputPlaceHolder text-gray-700"
                  />
                  <custom-date-picker
                      v-model="coupon.startDate"
                      auto-submit
                      format="YYYY-MM-DD HH:mm"
                      display-format="jYYYY/jMM/jDD HH:mm"
                      class=""
                      :element="`start${index}`"
                      type="datetime"
                      color="#5c6bc0"
                      :min="today"
                  ></custom-date-picker>
                </b-form-group>
              </b-col>

              <!-- Field:  endTime -->
              <b-col
                  cols="12"
                  md="6"
              >
                <b-form-group
                    label="تاریخ و زمان پایان"
                    label-for=""
                >
                  <input
                      type="text"
                      :id="`end${index}`"
                      placeholder="پایان"
                      v-model.trim="coupon.endDate"
                      class="form-control form-control-lg w-11/12 2xl:h-12 h-10 mx-auto mt-1 mb-1 text-sm block w-full rounded-full text-center bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder-inputPlaceHolder text-gray-700"
                  />
                  <custom-date-picker
                      v-model="coupon.endDate"
                      auto-submit
                      format="YYYY-MM-DD HH:mm"
                      display-format="jYYYY/jMM/jDD HH:mm"
                      class=""
                      :element="`end${index}`"
                      type="datetime"
                      color="#5c6bc0"
                      :min="today"
                  ></custom-date-picker>
                </b-form-group>
              </b-col>

              <!-- Field: execution -->
              <b-col
                  cols="12"
                  md="6"
              >
                <b-form-group
                    label="نوع اجرا"
                    label-for="execution"
                >
                  <v-select
                      v-model="coupon.execution"
                      :options="executionOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="execution"
                  />
                </b-form-group>
              </b-col>

              <!-- Field:  sessionsCount -->
              <b-col
                  v-if="coupon.execution === 1"
                  cols="12"
                  md="6"
              >
                <b-form-group
                    label="تعداد کل"
                    label-for="baseQuantity"
                >
                  <b-form-input
                      id="baseQuantity"
                      v-model="coupon.baseQuantity"
                  />
                </b-form-group>
              </b-col>

              <!-- Field:  sessionsCount -->
              <b-col
                  v-if="coupon.execution === 1"
                  cols="12"
                  md="6"
              >
                <b-form-group
                    label="تعداد باقی مانده"
                    label-for="maintainedQuantity"
                >
                  <b-form-input
                      id="maintainedQuantity"
                      v-model="coupon.maintainedQuantity"
                  />
                </b-form-group>
              </b-col>

              <!-- Field:  sessionsCount -->
              <b-col
                  v-if="coupon.execution === 2"
                  cols="12"
                  md="6"
              >
                <b-form-group
                    label="شناسه کاربر"
                    label-for="userId"
                >
                  <b-form-input
                      id="userId"
                      v-model="coupon.userId"
                  />
                </b-form-group>
              </b-col>

            </b-row>
          </b-form>

          <!-- Action Buttons -->
          <b-button
              variant="primary"
              class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="updateCoupon"
          >
            ثبت
          </b-button>

        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAlert, BAvatar,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCol,
  BDropdown,
  BDropdownItem, BForm, BFormGroup, BFormInput, BFormTextarea,
  BImg,
  BLink, BMedia,
  BRow, BTab, BTabs
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {CouponUpdateRequest} from "@/libs/Api/Coupon";
import {CouponGetRequest} from "@/libs/Api/Coupon";

export default {
  name: "CouponEdit",
  title:"ویرایش کوپن - پنل ادمین مکس ",
  computed:{
    currentDate(){
      let today = new Date();
      let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      return  date+' '+time;
    },
    today(){
      // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      // let test = new Date().toLocaleDateString('fa-IR',options);
      // return  test.replace(/([۰-۹])/g, token => String.fromCharCode(token.charCodeAt(0) - 1728));
      return new Date()
    }
  },
  data(){
    return{
      coupon:null,
      couponId:this.$route.params.id,
      typeOptions:[
        {label : 'درصد' , value : 1},
        {label : 'مبلغ' , value : 2}
      ],
      executionOptions:[
        {label : 'عمومی' , value : 1},
        {label : 'شخصی' , value : 2}
      ],
    }
  },
  async created(){
    await this.getCoupon();
  },
  methods:{
    async getCoupon(){
      let _this = this;
      let data={
        couponId : _this.couponId
      }

      let couponGetRequest = new CouponGetRequest(_this);
      couponGetRequest.setParams(data)
      await couponGetRequest.fetch(function (content){
        _this.coupon = content
      },function (error){
        console.log(error)
      })
    },
    async updateCoupon(){
      let _this = this;

      let couponUpdateRequest = new CouponUpdateRequest(_this);
      couponUpdateRequest.setParams(_this.coupon);
      await couponUpdateRequest.fetch(function (content){
        console.log(content)
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        })
        _this.getCoupon();
      },function (error){
        console.log(error)
      })
    },
  },
  components:{
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BTab,
    BTabs,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BMedia,
    BAvatar,
    vSelect,
  },
}
</script>

<style scoped>

</style>